import { registerSW } from 'virtual:pwa-register';

if (import.meta.env.PROD) {
  const updateSW = registerSW({
    onNeedRefresh() {
      console.log('New content is available. Please refresh.');
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: updateSW }),
      );
    },
    onOfflineReady() {
      console.log('App is offline ready.');
    },
    onRegisteredSW() {
      console.log('FTL service worker registered.');
    },
    onRegisterError(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
