import * as Sentry from '@sentry/vue';
import {
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getAuth,
  getIdTokenResult,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
} from 'firebase/auth';
import { computed, ref } from 'vue';

import { useFirebase } from '@/store/firebase';

const { firebaseApp } = useFirebase();
const auth = getAuth(firebaseApp);

const emulatorHost = import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_HOST;
if (emulatorHost) {
  console.log(`using auth emulator host ${emulatorHost}`);
  connectAuthEmulator(auth, `http://${emulatorHost}`);
}

// Set up global singleton user state
const user = ref(auth.currentUser);
const isAdmin = ref(false);
const isSuperAdmin = ref(false);

// This promise resolves after the *first* state change,
// which happens when the app is first loaded. This can
// be used to prevent checking the user's auth state before it is known.
const authInitialization = new Promise((resolve, reject) => {
  // Set up the auth state change listener.
  // Yes, it's a bit weird that this is inside a Promise...
  // But it should continue working even after the Promise resolves.
  onAuthStateChanged(
    auth,
    async (newUser) => {
      try {
        user.value = newUser;
        if (newUser) {
          const idTokenResult = await getIdTokenResult(newUser);
          isAdmin.value = !!idTokenResult.claims.admin;
          isSuperAdmin.value = !!idTokenResult.claims.superAdmin;
        } else {
          isAdmin.value = false;
          isSuperAdmin.value = false;
        }

        // set sentry user context
        Sentry.setUser({
          id: newUser?.uid,
          email: newUser?.email ?? undefined,
        });
      } catch (error) {
        return reject(error);
      }

      // resolve the outer promise
      return resolve(true);
    },
    reject,
  );
});

const createEmailPasswordAccount = (email: string, password: string) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

const sendPasswordReset = (email: string) => {
  return sendPasswordResetEmail(auth, email);
};

const signInWithPassword = (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const signInWithFacebook = () => {
  const provider = new FacebookAuthProvider();
  return signInWithRedirect(auth, provider);
};

const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  return signInWithRedirect(auth, provider);
};

export function useAuth() {
  return {
    //state
    authInitialization,
    isAdmin,
    isSuperAdmin,

    // getters
    email: computed(() => user.value?.email),
    isAuthenticated: computed(() => !!user.value),
    name: computed(() => user.value?.displayName ?? ''),
    photo: computed(() => user.value?.photoURL),
    userId: computed(() => user.value?.uid),

    // actions
    createEmailPasswordAccount,
    sendPasswordReset,
    signInWithPassword,
    signInWithFacebook,
    signInWithGoogle,
    signOut: () => auth.signOut(),
  };
}
