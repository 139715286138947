import { IonicVue } from '@ionic/vue';
import * as Sentry from '@sentry/vue';
import { createApp } from 'vue';

import App from '@/App.vue';

import '@/registerServiceWorker';

import router from '@/router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
/* FTL Theme */
import '@/theme/variables.css';
import '@/theme/ftl.css';

const app = createApp(App)
  .use(IonicVue, {
    innerHTMLTemplatesEnabled: true,
  })
  .use(router);

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: 'https://46c312c3d6ad42e2a75146dea868610e@o1111867.ingest.sentry.io/6141123',
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT ?? 'development',
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.replayIntegration(),
    ],
    ignoreErrors: [
      /^Error: Loading CSS chunk .* failed/,
      /^Error: Unable to preload CSS for .*/,
      /^TypeError: Load failed$/,
      /^AbortError: AbortError$/,
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    tracePropagationTargets: ['localhost', 'nextgenftl.com', /^\//],

    // Capture Replay for 5% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,

    denyUrls: [
      // ezoic stuff
      /the\.gatekeeperconsent\.com/i,
      /\/cmbdv2\.js/i,
    ],
  });
}

router.isReady().then(() => {
  app.mount('#app');
});
