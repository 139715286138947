import { doc, DocumentReference, runTransaction } from 'firebase/firestore';
import {
  getMessaging,
  getToken,
  isSupported,
  Messaging,
  onMessage,
} from 'firebase/messaging';

import {
  MessagingSubscription,
  Topic,
} from '@/models/messaging-subscription.interface';
import { useAnalytics } from '@/store/analytics';
import { useAppNotification } from '@/store/app-notification';
import { useAuth } from '@/store/auth';
import { useFirebase } from '@/store/firebase';
import { useFirestore } from '@/store/firestore';
import { useStorage } from '@/store/storage';

const vapidKey = import.meta.env.VITE_FIREBASE_VAPID_PUBLIC_KEY;

const storageKeys = {
  PERMISSION_REQUESTED_AT: 'messaging:permissionRequestedAt',
  PERMISSION_REQUEST_COUNT: 'messaging:permissionRequestCount',
  LAST_SUBSCRIBED_AT: 'messaging:lastSubscribedAt',
};
const DAYS_TO_WAIT_TO_RESUBSCRIBE = 7;
const DAYS_TO_WAIT_BEFORE_ASKING_AGAIN = 7;
const MAX_ASKS = 3;

const FTL_BADGE_URL = '/img/icons/apple-touch-icon-120x120-v4.png';
const FTL_ICON_URL = '/img/icons/android-chrome-192x192-v4.png';

const { logEvent } = useAnalytics();
const { alertNotification } = useAppNotification();
const { userId } = useAuth();
const { firebaseApp } = useFirebase();
const { firestore: db } = useFirestore();
const { getKey, setKey } = useStorage();

// initialize immediately on module load
const messagingPromise = initFirebaseMessaging();

async function initFirebaseMessaging(): Promise<Messaging | false> {
  // prevent unhandled errors thrown by firebase messaging
  const supported = await isSupported();
  if (!supported) {
    console.log('messaging not supported :(');
    return false;
  }
  const messaging = getMessaging(firebaseApp);

  // set up listener for incoming messages when app is open
  onMessage(messaging, async (payload) => {
    console.log('Received push notification', payload);
    if (payload.notification) {
      const { title, body, image, icon } = payload.notification;
      const t = title ?? 'FTL Alert';
      // image property is not supported in all browsers
      const opts: NotificationOptions & { image?: string } = {
        body,
        badge: FTL_BADGE_URL,
        icon: icon ?? FTL_ICON_URL,
        image,
      };
      const swRegistration = await navigator?.serviceWorker?.getRegistration();
      if (swRegistration) {
        await swRegistration.showNotification(t, opts);
      } else {
        if ('Notification' in window) {
          new Notification(t, opts);
        }
      }
    }
  });

  return messaging;
}

function createNewMessagingSubscription(userId: string): MessagingSubscription {
  return {
    userId,
    tokens: [],
    // subscribe to all known topics by default
    topics: Object.values(Topic),
    createdAt: new Date(),
    updatedAt: new Date(),
  };
}

function askUserWithAlert() {
  return new Promise<boolean>((resolve) => {
    alertNotification({
      header: 'Enable FTL Notifications',
      subHeader: 'Never miss a lockout (again)!',
      message:
        'FTL can send you push notifications for: ' +
        '<ul><li>Game Week lockout</li>' +
        '<li>Leaderboard Updates</li>' +
        '<li>Important announcements</li></ul> ' +
        'You must also select "allow" when prompted by your browser/device.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => resolve(false),
        },
        {
          text: 'Allow',
          handler: () => resolve(true),
        },
      ],
    }).catch((error) => {
      console.error(error);
      resolve(false);
    });
  });
}

async function getMessagingToken() {
  const messaging = await messagingPromise;
  if (!messaging) return;

  const token = await getToken(messaging, {
    vapidKey,
  });
  await setKey(storageKeys.LAST_SUBSCRIBED_AT, new Date());
  logEvent('subscribe_to_notifications');

  try {
    if (userId.value) {
      await saveUserMessagingToken(userId.value, token);
    }
  } catch (error) {
    console.error(error);
  }

  return token;
}

async function init() {
  // only init if user has opted in to notifications
  if (isOptedInToNotifications()) {
    await subscribeToNotifications();
  }
}

function isOptedInToNotifications() {
  return 'Notification' in window && Notification?.permission === 'granted';
}

function isOptedOutOfNotifications() {
  return 'Notification' in window && Notification?.permission === 'denied';
}

async function requestPermission() {
  if (!('Notification' in window)) {
    return false;
  }
  if (isOptedInToNotifications()) {
    return true;
  } else if (isOptedOutOfNotifications()) {
    // TODO: beg user to allow notifications?
    return false;
  }

  const requestedPermissionAt = await getKey<Date>(
    storageKeys.PERMISSION_REQUESTED_AT,
  );
  const requestCount =
    (await getKey<number>(storageKeys.PERMISSION_REQUEST_COUNT)) ?? 0;
  if (
    requestCount >= MAX_ASKS ||
    !hasBeenDaysSince(DAYS_TO_WAIT_BEFORE_ASKING_AGAIN, requestedPermissionAt)
  ) {
    return false;
  }

  const response = await askUserWithAlert();
  await setKey(storageKeys.PERMISSION_REQUESTED_AT, new Date());
  await setKey(storageKeys.PERMISSION_REQUEST_COUNT, requestCount + 1);
  logEvent('requested_notification_permission_soft', { response });

  if (response) {
    // Only ask for browser permission if user has already said yes to the alert.
    // The browser will only ask once, so we don't want to use up that chance if the user says no!
    const browserPermission = await Notification.requestPermission();
    logEvent('requested_notification_permission', {
      response: browserPermission,
    });
    return browserPermission === 'granted';
  }

  return response;
}

async function requestPermissionAndSubscribe() {
  const permission = await requestPermission();
  if (permission) {
    await subscribeToNotifications();
  } else {
    console.log('notification permission denied :(');
  }
}

async function subscribeToNotifications() {
  const lastSubscribedAt = await getKey<Date>(storageKeys.LAST_SUBSCRIBED_AT);
  // if it has been more than 7 days since last subscribed, subscribe again
  if (
    !lastSubscribedAt ||
    hasBeenDaysSince(DAYS_TO_WAIT_TO_RESUBSCRIBE, lastSubscribedAt)
  ) {
    await getMessagingToken();
  }
}

function hasBeenDaysSince(days: number, date: Date | undefined) {
  if (!date) {
    return true;
  }
  return date.getTime() < Date.now() - days * 24 * 60 * 60 * 1000;
}

async function saveUserMessagingToken(userId: string, token: string) {
  const userMessagingSubscriptionRef = doc(
    db,
    `messagingSubscriptions/${userId}`,
  ) as DocumentReference<MessagingSubscription>;

  await runTransaction(db, async (t) => {
    const userMessagingSubscriptionSnap = await t.get(
      userMessagingSubscriptionRef,
    );
    let userMessagingSubscription = userMessagingSubscriptionSnap.data();
    if (!userMessagingSubscription) {
      userMessagingSubscription = createNewMessagingSubscription(userId);
    } else {
      userMessagingSubscription.updatedAt = new Date();
    }
    const existingToken = userMessagingSubscription.tokens.find(
      (t) => t.token === token,
    );
    if (!existingToken) {
      userMessagingSubscription.tokens.push({
        token,
        createdAt: new Date(),
        updatedAt: new Date(),
      });
    } else {
      existingToken.updatedAt = new Date();
    }

    t.set(userMessagingSubscriptionRef, userMessagingSubscription);
  });
}

export function useMessaging() {
  return {
    //actions
    getMessagingToken,
    init,
    isOptedInToNotifications,
    isOptedOutOfNotifications,
    requestPermission,
    requestPermissionAndSubscribe,
  };
}
